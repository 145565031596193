import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import url from './url';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { Card, Spinner } from 'react-bootstrap'
import moment from 'moment';
import ShowNav from './auth/ShowNav';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageContext } from '../App';
import Pagination from "react-js-pagination";
import {

    Image

} from 'semantic-ui-react'
const initialstate = []
const AllDiagnosis = () => {
    const languagecontext = useContext(LanguageContext)
    const { t, i18n } = useTranslation()
    const [spin, setSpin] = useState('false');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [diagnosis, setDiagnosis] = useState([]);
    const [empty, setEmpty] = useState('');
    const [language, setLanguage] = useState('en');
    const [activePage, setActivePage] = useState('');
    const [perpage, setPerPage] = useState('')
    const [totalitem, setTotalItems] = useState('')
    const [humanfigure, setHumanFigure] = useState('../assets/Webappimages16/')

    useEffect(() => {
        // console.log(languagecontext.languagestate)
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }

        fetchAllDiagnosis()


    }, [languagecontext.languagestate])
    useEffect(() => {
        // console.log(languagecontext.languagestate)
        // if (languagecontext.languagestate == 'en') {
        //     setLanguage('en')

        // } else {
        //     setLanguage('zh_TW')

        // }

        fetchAllDiagnosis()


    }, [language])
    // Fetch updates every 10 seconds
    //     useEffect(() => {
    //         const interval = setInterval(() => {
    //     //   if (languagecontext.languagestate == 'en') {
    //     //         setLanguage('en')

    //     //     } else {
    //     //         setLanguage('zh_TW')

    //     //     }
    //     setLanguage('language')
    //   }, 1000000);
    //   return () => clearInterval(interval);
    // }, []);

    const fetchAllDiagnosis = (url_ = null) => {
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }
        if (!url_) {
            url_ = "diagnosis/all";
        }
        setSpin('border')
        // setDiagnosis([])

        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                language: languagecontext.languagestate
            }
        };

        // alert(language)
        // setDiagnosis([])


        axios.get(url + url_, config)
            .then(res => {
                // console.log(res.data.meta);
                setActivePage(res.data.meta.page)
                setTotalItems(res.data.meta.total)
                setPerPage(res.data.meta.per_page)
                setSuccess(res.data.message);
                setDiagnosis(initialstate)
                let arr = res.data.result;
                // console.log("start json from server")
                // console.log(res.data)
                // console.log(res.data.result)
                // console.log("end json from server")
                if (arr === undefined || arr.length == 0) {
                    setEmpty('true')

                }
                arr.map(arr => {
                    if (arr.status != 3) {
                        setDiagnosis(diagnosis => [...diagnosis, arr])

                    }
                })



            }).catch(e => {
                // setError('We didn\'t find your email in the system Kindly create an account')

                setError(e.message)

            }).then(r => {
                setSpin('false')

            })

    }
    const renderresultsimage = (healthstatus) => {
        if (healthstatus == 0) {
            return <img src="../assets/green.png" />
        } else if (healthstatus == 1) {
            return <img src="../assets/yellow.png" />

        } else {
            return <img src="../assets/red.png" />
        }

    }
    const renderresultscolor = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            return "go-results"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "warning-results"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "red-results"
        }

    }
        const renderresultsicon = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            
            return "Go.png"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Caution.png"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Stop.png"
        }

    }
    const handlePageChange = (e) => {
        let pageUrl = "diagnosis/all?page=" + e;
        fetchAllDiagnosis(pageUrl);
    }
    const renderdiagnosis = diagnosis.map((diagnosis, index) => (

        <div className="col-sm-6" style={{ paddingTop: '10px' }}>
            <Card
                className="card p-1 bg-transparent"
                // style={{ border: 'none' }}
            >
                {/* <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-5" style={{ background: '#173f82' }}>
                        <Image size='small' src='../assets/docsun.png' sstyle={{ height: 50, paddingLeft: "-20px" }} />
                    </div>
                    <div className="col-sm-3" />
                </div> */}

                <Card.Body>


                    {diagnosis.created_at != null ? <>
                        <p>
                            <b> {moment(diagnosis.created_at).format('YYYY-MM-DD h:mm:ss a')}</b>
                        </p>
                        {/* <p> */}
                        {/* <strong>{t('diagnosis_results.results')}:</strong>
                        {renderresultsimage(diagnosis.health_status)} */}
                        <h4><strong><h4> {t('diagnosis_results.health_report')}</h4></strong></h4>


                        <p className={renderresultscolor(diagnosis.health_status)}>
                            {diagnosis.diagnosis}
                        </p>
                        {/* </p> */}
                        {diagnosis.status == 3 ? <div style={{ fontSize: "15px" }}>
                            {/* failed */}
                            <em class="bold">
                                {t('diagnosis_results.failed_results.title')}
                            </em>

                            <ol>
                                <li>{t('diagnosis_results.failed_results.no1')}</li>
                                <li>{t('diagnosis_results.failed_results.no2')}</li>
                                <li>{t('diagnosis_results.failed_results.no3')}</li>
                            </ol>





                        </div> : <>
                            {/* SUccessful */}
                            {/* <p> */}
                            {/* <b>{t('diagnosis_results.viral_signs')}</b> */}
                            {/* </p > */}

                            <div className="row">
                                <div className="col-sm-9" >
                                        <p>
                                            {/* <div className="curveresults">
                                                <div className="row">
                                                    <div className="col-sm-3" >
                                                        <img src="../assets/Webappimages16/Icon01.png" height="60px" width="60px" alt="Sp02" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <b>{t('diagnosis_results.spo2')}:</b> <br />
                                            <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.spo2} </span><br />
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div> */}
                                            <div class="line"></div>
                                                                                        <div className="curveresults">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <img src="../assets/Webappimages16/Icon02.png" height="60px" width="60px" alt="Breathe" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <b>{t('diagnosis_results.breathing_rate')}:
                                                <br /></b> <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.respiration_rate} </span> <br />

                                                    </div>
                                                </div>
                                                
                                                

                                            </div>
                                            <div class="line"></div>
                                            <div className="curveresults">
                                                <div className="row">
                                                    <div className="col-sm-3" >
                                                        <img src="../assets/Webappimages16/Icon03.png" width="60px" alt="Heart rate" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <b> {t('diagnosis_results.heart_rate')}:</b> <br />
                                            <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.heart_rate} </span><br />
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                            
                                            {/* <div class="line"></div>
                                            <div  className="curveresults">
                                                <div className="row">
                                                    <div className="col-sm-3" >
                                                        <img src="../assets/Webappimages16/Icon04.png" height="60px" width="60px" alt="Temperature" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <span><b>{t('diagnosis_results.temperature')}: <br />
                                            </b></span> <span className={renderresultscolor(diagnosis.health_status)}> {diagnosis.temperature == "" ? <> n/a </> : <>{ diagnosis.temperature}</>}</span> <br />
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div> */}
                                            {/* <div class="line"></div>
                                            <div className="curveresults">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <img src="../assets/Webappimages16/Icon05.png" height="60px" width="60px" lat="eye" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <b>{t('diagnosis_results.eye_coloration')}: </b><br>
                                            </br><span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.eye_coloration}</span> <br />

                                                    </div>
                                                </div>
                                            
                                            

                                            </div>
                                            <div class="line"></div> */}
                                            
                                            
                                            
                                            {/* <div class="line"></div> */}


                                        {/* <div style={{ background: '#0c3668' }} className="curveresults">
                                                <div class="row">
                                                    <div className="col-sm-2" style={{ paddingTop: '5px' }}>
                                                    <img src="../assets/web2images/Iconbloodpressure.png" height="30px" width="30px" lat="eye" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                                    <b style={{ color: 'white' }}> {t('diagnosis_results.blood_pressure')}:
                                            </b> <br /><span style={{ color: 'white' }}>{diagnosis.blood_pressure_code} {diagnosis.blood_pressure_range}</span> <br />
                                                    </div>
                                                </div>
                                            
                             
                                        </div>
                                        <div style={{ background: '#09284d' }} className="curveresults">
                                        <div class="row">
                                                    <div className="col-sm-2" style={{ paddingTop: '5px' }}>
                                                    <img src="../assets/web2images/Iconsugarlevel.png" height="30px" width="30px" lat="eye" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                    <b style={{ color: 'white' }}> {t('diagnosis_results.blood_sugar')}: <br />
                                            </b><span style={{ color: 'white' }}>{diagnosis.blood_sugar_code} {diagnosis.blood_sugar_range}</span> <br />
                                                    </div>
                                                </div>
                                             
                                            
                                        </div> */}
                                    </p>
                                </div>
                                <div className="col-sm-3 d-none d-md-block" style={{ color: 'white', paddingTop:'30px' }}>
                                <img src={humanfigure+renderresultsicon(diagnosis.health_status)} alt="Figure" height="100px" />
                            </div>
                            </div>

                        </>}




                    </> : <>
                        {t('diagnosis_results.no_results')}

                    </>}
                </Card.Body>
            </Card>



        </div >
    ))

    return (<div className="container1">
        <ShowNav />


        <div className="row1" style={{ paddingTop: "60px", paddingLeft:'37px' }}>
            <div className="col-sm-12">
                <nav aria-label="breadcrumb">

                <ol class="breadcrumb">
                    <li class="breadcrumb-item">  <Button variant="link" as={Link} to="/dashboard"  > {t('navigation.dashboard')}</Button></li>
                    <li class="breadcrumb-item active" aria-current="page" style={{ paddingTop: '5px' }}>{t('navigation.my_history')}</li>
                </ol>
            </nav>
            </div>
        </div>
        <div style={{paddingLeft:'50px'}}>
            <div className="row">
                 <div className="col-sm-3">
               <h4> {t('diagnosis_results.results')} </h4>
                </div> 
            </div>
            
            <div className="row">

                <div className="col-sm-3">
                    <button class="btn background-blue" style={{ borderColor: "#28696D", color: "#ffffff" }} onClick={() => fetchAllDiagnosis()} >
                        <i class="fas fa-sync fa-fw"></i> {t('diagnosis_results.reload_results')}
                    </button>
                </div>
            </div>
            <div className="row" style={{paddingLeft:'18px'}}>
            <div className="col-sm-12" style={{paddingLeft:'18px'}}>
                <Spinner animation={spin} variant="primary" style={{paddingLeft:'18px'}} />
                {error ? <>
                    <div className="alert alert-danger">
                        {error}
                    </div>
                </> : <></>}

            </div>
            </div>
            </div>
        
        

        <br />
        <div className="row" style={{ paddingLeft: "50px" }}>
            <div style={{ paddingLeft: "15px" }}>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perpage}
                    totalItemsCount={totalitem}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"

                />
            </div>
            {renderdiagnosis}
            <div style={{ paddingLeft: "20px" }}>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perpage}
                    totalItemsCount={totalitem}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"

                />
            </div>

        </div>


    </div>);
}

export default AllDiagnosis;
