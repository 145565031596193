import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';
import { Spinner } from 'react-bootstrap';

import { Card, Button } from 'react-bootstrap';
import axios from 'axios';
import url from '../url';
const ResendVerication = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [spin, setSpin] = useState('false')
    const [success, setSuccess] = useState('')
    
    const sendemail = () => {
        setError('')
        setSuccess('')
        if (email == '') {
            setError('Enter your email')
            return;
        }

        setSpin('grow')
            var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      email: email,
      
        };
        
        axios.post(url + 'auth/resend-verification',{email})
            .then(res => {
                console.log(res)
                setSuccess(res.data.message)
            
            }).catch(e => {
                setError('We didn\'t find your email in the system Kindly create an account')
                console.log(e)
            
            }).then(r => {
                setSpin('false')
            
        })
        
    }
    return (<div className="container" style={{ height: '100vh' }}>
        <br />
            <br />
        <div className="row">
            
            <div className="col-sm-3"></div>
            <div className="col-sm-6">

                <Card style={{backgroundImage: `url("https://app-test.docsun.health/img/bg.9cd18d37.jpg")`, color:'white'}}>
                    <Card.Header ><div className="row">
                        <div className="col-sm-7">
                            <Image size='small' src='https://app-test.docsun.health/img/docsun.70f9760e.png' /> 
                            
                        </div>
                        <div className="col-sm-4">
                            <span className="float-right">Reset Password</span>

                        </div>
                    </div></Card.Header>
                    <Card.Body>
                        <div className="row">
                        <div className="col-sm-12">
                        <Spinner animation={spin} variant="primary" />
                        {error ? <>
                            <div className="alert alert-danger">
                            {error}
                            </div>
                        </> : <></>}
                        {success ? <>
                            <div className="alert alert-info">
                            {success}
                            </div>
                        </> : <></>}
                        
                        </div>
                    </div>
                    
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                        className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <Button variant="primary" onClick={sendemail }>Email</Button>
                            </div>
                        </div>
                </Card.Body>
                </Card>

            </div>
            <div className="col-sm-3"></div>
        </div>
    </div>    );
}
 
export default ResendVerication;