/* eslint-disable */
import React, { Component } from 'react';

// import './App.css';

import 'video.js/dist/video-js.css';
import videojs from 'video.js';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import { Card, Alert, Button, Spinner } from 'react-bootstrap';
// import { notification, Space } from 'antd';
/*
// Required imports when recording audio-only using the videojs-wavesurfer plugin
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;

// Register videojs-wavesurfer plugin
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
*/

// register videojs-record plugin with this import
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';
import DashboardDiagnosis from './DashboardDiagnosis';
import axios from 'axios';
import url from './url';
import ShowNav from './auth/ShowNav';
import { withTranslation } from 'react-i18next';
import VideoPlayerComponent from './VideoPlayer';
import { NotificationContainer, NotificationManager } from 'react-notifications';
const videoJsOptions = {
    controls: true,
    // bigPlayButton: true,
    width: 500,
    height: 250,
    fluid: true,
    plugins: {
        /*
        // wavesurfer section is only needed when recording audio-only
        wavesurfer: {
            backend: 'WebAudio',
            waveColor: '#36393b',
            progressColor: 'black',
            debug: true,
            cursorWidth: 1,
            msDisplayMax: 20,
            hideScrollbar: true,
            displayMilliseconds: true,
            plugins: [
                // enable microphone plugin
                WaveSurfer.microphone.create({
                    bufferSize: 4096,
                    numberOfInputChannels: 1,
                    numberOfOutputChannels: 1,
                    constraints: {
                        video: false,
                        audio: true
                    }
                })
            ]
        },
        */
        record: {
            audio: true,
            video: true,
            maxLength: 15,
            debug: true
        }
    }
};
var username = sessionStorage.getItem('username', false);
class RecordVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false,
            temperature: 0,
            profile: '1',
            selectedFile: null,
            spin: false,
            visible: 'none',
            measure: 'Celcius',
            convertedtemp: 0,
            error: '',
            success: '',
            accepted: false,
            donerecording: false,
            show: true,
            preview: false,
            processing: false,
            showdiagnosisdashboard: false,
            production: false,
            cameraerror: '0',
            showfullrow: 'block',
        };
        this.uploadvideo = this.uploadvideo.bind(this)
        this.setTemperature = this.setTemperature.bind(this)
        this.onChangeValue = this.onChangeValue.bind(this)
        this.handleAccept = this.handleAccept.bind(this)
        this.chooseFile = this.chooseFile.bind(this)


    }

    // openNotificationWithIcon = type => {
    //     notification[type]({
    //         message: 'Notification Title',
    //         description:
    //             'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    //     });
    // };


    onFileChange = event => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        // console.log(this.state.selectedFile)
        // console.log('this.state.selectedFile')
        // console.log(event.target.files[0])
        this.setState({ preview: true })



    };

    componentDidMount() {
        // if (this.state.record) {
        this.initializevidorecord();
        // }
        // this.fetchProfile();

    }
    setTemperature(value) {
        // set temperature
        var temp = this.state.temperature;
        if (value == 'Fahrenheit') {
            var newtemp = (temp - 32) * 5 / 9;
            this.setState({ convertedtemp: newtemp })
        } else {
            this.setState({ convertedtemp: temp })

        }

    }
    fetchProfile() {
        // alert('yes')
        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'auth/profile', config)
            .then(res => {
                // console.log(res)
                // alert(res.data)
            }).catch(e => {
                this.setState({ error: 'Error fetching profile' })
            })
    }
    initializevidorecord() {
        // instantiate Video.js



        this.getUsername();
        this.player = videojs(this.videoNode, videoJsOptions, () => {
            // print version information at startup
            const version_info = 'Using video.js ' + videojs.VERSION +
                ' with videojs-record ' + videojs.getPluginVersion('record') +
                ' and recordrtc ' + RecordRTC.version;
            videojs.log(version_info);
        });

        // device is ready
        this.player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        // user clicked the record button and started recording
        this.player.on('startRecord', () => {
            console.log('started recording!');
        });

        // user completed recording and stream is available
        this.player.on('finishRecord', () => {
            // recordedData is a blob object containing the recorded data that
            // can be downloaded by the user, stored on server etc.
            console.log('finished recording: ', this.player.recordedData);
            // alert('finished recording')
            this.setState({ selectedFile: this.player.recordedData });
            this.setState({ donerecording: true })
        });

        // error handling
        this.player.on('error', (element, error) => {
            console.warn(error);
        });

        this.player.on('deviceError', () => {
            // alert('hey')
            this.setState({ cameraerror: '2' });
            // this.setStateset({ cameraerror: this.player.deviceErrorCode })
            console.error('device error:', this.player.deviceErrorCode);
            NotificationManager.warning('Camera and microphone', 'Please enable permission in the browser settings', 6000);
        });
        // this.player.record().getDevice()
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }
    getUsername() {
        // alert();
        var username = sessionStorage.getItem('username', false);
    }
    uploadvideo() {
        // console.log(this.state.selectedFile);

        this.setState({ error: '' })
        this.setState({ success: '' })

        if (this.state.selectedFile == null) {
            alert('Please upload video')
            return

        }


        if (this.state.selectedFile.size > 50000000) {
            this.setState({ error: 'Uploaded video should be less than 50 MB' })
            return;
        }

        if (this.state.accepted == '') {
            this.setState({ error: 'Accept terms and conditions' })
            return
        }
        // if (this.state.profile == '') {
        //     this.setState({ error: 'Select light type' })
        //     // setError(t('responses.fields_required'))
        //     return
        // }


        this.setState({ spin: 'border' })
        this.setState({ processing: true })
        // console.log(this.state.selectedFile)

        // Create an object of formData
        var file = new File([this.state.selectedFile], this.state.selectedFile.name);
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "video",
            file,

        );
        formData.append(
            "temperature",
            this.state.convertedtemp,

        );
        formData.append(
            "profile",
            this.state.profile,

        );

        // Details of the uploaded file
        //   console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.post(url + "diagnosis/upload", formData, config)
            .then(res => {
                // alert('yes')
                // alert(res)
                // console.log(res)
                // this.setState({ success: res.data.message })
                NotificationManager.success('Upload Successful', 'Wait as we process your Scan', 10000);


            }).catch(e => {
                this.setState({ error: 'Error uploading the video' })

            }).then(res => {
                this.setState({ spin: 'false' })
                this.setState({ processing: false })
                this.setState({ preview: false })
                this.player.record().reset()
                this.setState({ visible: 'none' })
                this.setState({ showfullrow: 'block' })
                
                this.setState({ selectedFile: null })
                this.setState({ showdiagnosisdashboard: true })


            })

    }
    onChangeValue(event) {
        //  console.log(event.target.value);
        this.setTemperature(event.target.value)

        //  if (event.target.value=='Fahrenheit') {
        //      var newtemp = (event.target.value - 32) * 5 / 9;
        //      this.setState({temperature:newtemp})
        //  } 
    }
    formatTemp() {
        // make
    }
    handleAccept(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    startVideo() {

        this.player.record().start()
    }
    setUpRecord() {
        // alert('We request you to  allow camera and microphone permission for this website');
        NotificationManager.success('Camera and microphone', 'Please allow the permission', 6000);
        // this.openNotificationWithIcon('success')
        this.player.record().getDevice()
        this.setState({ visible: 'block' })
        this.setState({showfullrow:'none'})
        this.setState({ preview: false })
        this.setState({ error: '' })
        this.setState({ success: '' })
        window.location.hash = "scanning"
        // document.getElementById('scanning').scrollIntoView();
    }
    stopRecord() {
        this.player.play()
        // this.player.record().reset()
        // this.setState({visible:'none'})
    }

    chooseFile() {
        document.getElementById('upload_video').click()
        this.setState({ preview: false })
        this.setState({ error: '' })
        this.setState({ success: '' })
    }

    render() {
        const recordstate = this.state.record
        // alert(recordstate)
        // if (this.state.record) {
        //     this.initializevidorecord();
        // }
        const visible = this.state.visible;
        const showfullrow = this.state.showfullrow;
        const { t } = this.props;

        return (

            <div className="container1" style={{ paddingTop: '35px', overflow: 'hidden' }}>
                <ShowNav />
                <NotificationContainer />


                {/* {this.state.cameraerror != '0' ? <>
                    <div className="alert alert-warning">

                        Kindly give permission to browser to use Camera
                    </div>
                </> : <>


                </>} */}


                <div className="row" id="app22">
                    <div className="col-sm-8" style={{ color: 'white' }}>
                        <span><h4>{t('dashboard.navdiscription')} </h4></span>
                    </div>
                    {/* <div className="col-sm-4" style={{ color: 'white' }}>
                        <span><h4>{t('dashboard.welcome')} {username}</h4></span>
                    </div> */}

                </div>
                {/* <hr /> */}

                <div className="row">
                    <div className="col-sm-12">
                        <Spinner animation={this.state.spin} variant="primary" />
                        {this.state.success ? <>
                            <div className="alert alert-info">
                                {this.state.success}
                            </div>
                        </> : <></>}

                        {this.state.error ? <>
                            <div className="alert alert-danger">
                                {this.state.error}
                            </div>
                        </> : <></>}
                    </div>
                </div>

                <div class="mb-3 text-center">
                    <h4 class="text-center">
                        <b>{t('login.title')}</b>
                    </h4>
                    <span class="text-center">
                        <h3> {t('login.subtitle')}</h3>
                    </span>
                </div>
                <div className="row" style={{padding:'7px'}} style={{display:showfullrow}}>
                    <div className="col-sm-12">
                        <img src={t('dashboard.instruction_image')} className="responsive" />
                    </div>
                </div>
                <br />
                <div style={{ display: visible, paddingTop:'10px' }}>
                <div className="row" >
                    <div className="col-sm-6" style={{ paddingRight: '30px' }}>
                            <span className="vertical-center" > <img src={t('dashboard.instruction_image_small')} className="responsive" /> </span>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                        </div>
                        
                        <div className="col-sm-6" style={{ paddingTop: '5px' }}>
                            <div className="row">
                                    <div className="col-sm-12">
                                        <div data-vjs-player>
                                            <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin" playsInline></video>
                                        </div>
                                    </div>
                            </div>
                            <div class="line"></div>
                            <div className="row">
                                {/* <div className="form-group" style={{ borderStyle: 'ridge', borderColor: '#28696D', borderWidth: "thin" }}> */}
                                    <div className="col-sm-4">
                                        <button className="btn btn-primary" onClick={() => this.startVideo()}>{t('dashboard.video_recording.buttons.start_recording')}</button>

                                    </div>

                                {this.state.donerecording ? <>
                                    <div className="col-sm-3">
                                            <button className="btn btn-info" onClick={() => this.player.play()}>{t('dashboard.preview')}</button>
                                        </div>
                                        
                                        <div className="col-sm-4">
                                            <button className="btn btn-success" onClick={this.uploadvideo}>{t('dashboard.button_upload')}</button>
                                        </div>
                                        {this.state.error ? <>
                                            <div className="alert alert-danger">
                                                {this.state.error}
                                            </div>
                                        </> : <></>}



                                    </> : <></>}




                                {/* </div> */}


                                
                            </div>


                            <br />


                        

                    </div>

                </div>
                </div>

                {/* start of split */}
                <div className="row">
                    <div className="col-sm-6">

                        {/* <img src="../assets/Instructions.png" alt="Figure" className="responsive zoom" /> */}
                        {/* {t('dashboard.start_scanning')} */}

                        {/* <img src={t('dashboard.instruction_image')} alt="Figure" className="responsive zoom" /> */}

                        <div
                            class="shadow border-0 p-1 bg-transparent"
                        >
                            <div className="form-group" style={{padding:'10px'}}>
                            <input
                                name="accepted"
                                type="checkbox"
                                checked={this.state.accepted}
                                onChange={this.handleAccept} /> {t('dashboard.terms')}
                            </div>
                            <i style={{ paddingTop: "50px", paddingBottom: "50px" }}><strong>{t('dashboard.high_demand_text')}</strong></i>
                            <br />
                            <br />

                            {/* <label className="bold"><strong>{t('dashboard.select_video')}</strong> <small class="text-white">*</small></label> */}
                            <div className="form-group" style={{ borderStyle: 'ridge', borderWidth: "thin", height: '60px' }}>

                                <div className="row">
                                    <div className="col-sm-8">
                                        <button className="btn btn-boston text-boston mt-2 btn-lg"
                                            style={{ height: "45px" }}
                                            type="button"
                                            onClick={() => this.setUpRecord()} >
                                            <i style={{ position: "relative", bottom: "5px", padding: '5px', width: '30px' }}
                                                className="fas text-white fa-camera fa-fw background-blue"
                                            ></i>
                                        </button>
                                        <span class="ml-1 mt-2" style={{ fontSize: "1em" }}>
                                            {t('dashboard.start_scanning')}</span>

                                    </div>
                                    {/* <div className="col-sm-3" style={{ paddingTop: "15px" }} id="scanning">


                                        
                                        <Button onClick={() => this.chooseFile()}
                                            class="float-end background-blue" style={{ height: "30px" }}>{t('dashboard.choose_file')}</Button>
                                        <input type="file" accept="video/*" onChange={this.onFileChange} id="upload_video" style={{ visibility: "hidden" }} />
                                    </div> */}

                                </div>

                            </div>

                        </div>
                        {/* <div className="row" style={{ paddingTop: "30px" }}>
                            <div className="col-sm-4">

                                {this.state.selectedFile == null ? <> {t('dashboard.nofile_selected')} </> : <></>}


                            </div>
                        </div> */}




                        <div className="row">
                            <div className="col-sm-4">


                                {this.state.selectedFile == null ? <></> : <>
                                    {/* <div className="row">
                                        <div className="col-sm-12">
                                            <button className="btn btn-primary" onClick={this.uploadvideo}><i class="fa fa-upload" aria-hidden="true"></i>{t('dashboard.button_upload')}</button>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h5><b>{t('dashboard.preview_here')}</b></h5>
                                            <br />
                                        </div>

                                    </div> */}

                                </>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <Spinner animation={this.state.spin} variant="primary" />
                                {this.state.success ? <>
                                    <div className="alert alert-info">
                                        {this.state.success}
                                    </div>
                                </> : <></>}

                                {this.state.error ? <>
                                    <div className="alert alert-danger">
                                        {this.state.error}
                                    </div>
                                </> : <></>}
                            </div>
                        </div>
                        {/* player */}
                        <div className="row">
                            <div className="col-sm-6">
                                {this.state.processing ? <>
                                    <Spinner animation={this.state.spin} variant="primary" /> {t('dashboard.processing')}

                                </> : <></>}
                            </div>
                        </div>

                        
                        
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-sm-6">
                        {/* Video Recorder */}
                            <div className="row">
                            <div className="col-sm-4">


                                {this.state.selectedFile == null ? <></> : <>
                                    {/* <div className="row">
                                        <div className="col-sm-12">
                                            <button className="btn btn-primary" onClick={this.uploadvideo}><i class="fa fa-upload" aria-hidden="true"></i>{t('dashboard.button_upload')}</button>
                                            <br />
                                            <br />
                                        </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="col-sm-12">
                                            <h5><b>{t('dashboard.preview_here')}</b></h5>
                                            <br />
                                        </div>

                                    </div> */}

                                </>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {this.state.preview ? <>
                                    <VideoPlayerComponent video={this.state.selectedFile} />

                                </> : <></>}
                                {/* <VideoPlayerComponent video={this.state.selectedFile} />   */}
                            </div>
                        </div>


                        {/* <span style={{ display: visible, border: '10px' }} >
                            <div className="row" style={{ padding: '5px' }}>
                                <div className="form-group" style={{ borderStyle: 'ridge', borderColor: '#28696D', borderWidth: "thin" }}>
                                    <div className="col-sm-4">
                                        <button className="btn btn-primary" onClick={() => this.startVideo()}>{t('dashboard.video_recording.buttons.start_recording')}</button>

                                    </div>

                                    {this.state.donerecording ? <>
                                        <div className="col-sm-3">
                                            <button className="btn btn-info" onClick={() => this.player.play()}>{t('dashboard.preview')}</button>
                                        </div>
                                        <div className="col-sm-4">
                                            <button className="btn btn-success" onClick={this.uploadvideo}>{t('dashboard.button_upload')}</button>
                                        </div>
                                        {this.state.error ? <>
                                            <div className="alert alert-danger">
                                                {this.state.error}
                                            </div>
                                        </> : <></>}



                                    </> : <></>}




                                </div>


                                <div className="row">
                                    <div className="col-sm-12">
                                        <div data-vjs-player>
                                            <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin" playsInline></video>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <br />




                            <br />
                            <br />
                            <br />
                        </span> */}



                        {/* Results */}
                        <div className="row">
                            <div className="col-sm-6">
                                {this.state.processing ? <>
                                    <Spinner animation={this.state.spin} variant="primary" /> {t('dashboard.processing')}

                                </> : <></>}
                            </div>
                        </div>
                        {this.state.showdiagnosisdashboard ? <> <DashboardDiagnosis /></> : <> <DashboardDiagnosis /></>}


                    </div>
                </div>

            </div>

        );
    }
}

// export default RecordVideo;

const MyComponent = withTranslation()(RecordVideo)

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
    return (
        // <Suspense fallback="loading">
        <MyComponent />
        // </Suspense>
    );
}