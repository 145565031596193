import axios from 'axios';
import React, { useEffect, useState,useContext } from 'react';
import url from './url';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import {Card,Spinner} from 'react-bootstrap'
import moment from 'moment';
import ShowNav from './auth/ShowNav';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageContext } from '../App';
import {useLocation} from "react-router-dom";
const initialstate = []

const ValidateDiagnosis = () => {

          const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
    const languagecontext= useContext(LanguageContext)
    const { t, i18n } = useTranslation()
     const [spin, setSpin] = useState('false')
    const [error, setError]=useState('')
    const [success, setSuccess]=useState('')
    const [diagnosis, setDiagnosis] = useState([])
    const [empty, setEmpty] = useState('')
    const [language, setLanguage]=useState('en')
    
   useEffect(() => {
        // console.log(languagecontext.languagestate)
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')
            
        } else {
            setLanguage('zh_TW')
            
        }
        
        fetchAllDiagnosis()

        
    }, [languagecontext.languagestate])
    useEffect(() => {
        // console.log(languagecontext.languagestate)
        // if (languagecontext.languagestate == 'en') {
        //     setLanguage('en')
            
        // } else {
        //     setLanguage('zh_TW')
            
        // }
        
        fetchAllDiagnosis()

        
    }, [language])
    // Fetch updates every 10 seconds
//     useEffect(() => {
//         const interval = setInterval(() => {
//     //   if (languagecontext.languagestate == 'en') {
//     //         setLanguage('en')
            
//     //     } else {
//     //         setLanguage('zh_TW')
            
//     //     }
//     setLanguage('language')
//   }, 1000000);
//   return () => clearInterval(interval);
// }, []);

    const fetchAllDiagnosis = () => {
                if (languagecontext.languagestate == 'en') {
            setLanguage('en')
            
        } else {
            setLanguage('zh_TW')
            
        }
        setSpin('border')
   
                var token=sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` },
//             params: {
//     user_key: id 
//   },
        };
        

        axios.get(url+'diagnosis/get-by-key')
            .then(res => {
                console.log(res)
                setDiagnosis(res.data.result)
            
            }).catch(e => {
                setError(e.message)
            
            }).then(r => {
                setSpin('false')
            
        })
        
    }
        const renderresultsimage = (healthstatus) => {
        if (healthstatus ==0) {
           return <img src="../assets/green.png" />
        } else if(healthstatus ==1){
            return <img src="../assets/yelloe.png" />
            
        }else {
             return <img src="../assets/red.png" />
        }
            
    }
    const renderdiagnosis = diagnosis.map((diagnosis, index) => (
        
            <div className="col-sm-6" style={{paddingTop:'10px'}}>
                            <Card className="card p-1 bg-transparent" style={{ border: 'rounded-pill', border:"1px solid #28696D", borderRadius:"17px"   }}>
           
            <Card.Body className="text-boston">
                
                 <p>
               <b> { moment( diagnosis.created_at).format('MM-DD-YYYY h:mm:ss a')}</b>
            </p>
            <p>
                    <b>{t('diagnosis_results.results')}:</b>  {renderresultsimage(diagnosis.health_status)}
                    <p>{ diagnosis.diagnosis}</p>
                </p>
                  {diagnosis.status == 3 ? <div style={{fontSize:"15px"}}>
                            {/* failed */}
                                                      <em class="text-warning bold">
                            {t('diagnosis_results.failed_results.title')}
                            </em>

                            <ol>
                                <li>{t('diagnosis_results.failed_results.no1')}</li>
                                <li>{t('diagnosis_results.failed_results.no2')}</li>
                                <li>{t('diagnosis_results.failed_results.no3')}</li>
                            </ol>
                            


                            
                        </div> : <>
                                {/* SUccessful */}
                                                <p>
                    <b>{t('diagnosis_results.viral_signs')}</b>
                </p >
                    <p className="bold" style={{padding:"10px"}}>
                        <img src="../assets/temp.png"  height="20px" width="20px" alt="Temperature" /> <span><b>{t('diagnosis_results.temperature')}:</b></span> <span style={{color:'white'}}>n/a</span> <br />
                         <img src="../assets/heart.png" height="20px" width="20px" alt="Heart rate" /><b> {t('diagnosis_results.heart_rate')}:</b> <span style={{color:'white'}}>{diagnosis.heart_rate} </span><br />
                        <img src="../assets/spo2.png"  height="20px" width="20px" alt="Sp02"/> <b>{t('diagnosis_results.spo2')}:</b> <span style={{color:'white'}}>{diagnosis.spo2} </span><br />
                         <img src="../assets/breathe.png" height="20px" width="20px" alt="Breathe" /><b>{t('diagnosis_results.breathing_rate')}:</b> <span style={{color:'white'}}>{diagnosis.respiration_rate} </span> <br />
                         <img src="../assets/eye.png" height="20px" width="20px" lat="eye" /> <b>{t('diagnosis_results.eye_coloration')}: </b><span style={{color:'white'}}>{diagnosis.eye_coloration}</span> <br />
                    </p>
                        
                        </>}
                


                
                

                  
            </Card.Body>
            </Card>
            


        </div>
    ))

    return (<div className="container1">
        {/* <ShowNav /> */}

        
        <div className="row">
            {/* <nav aria-label="breadcrumb">
                
  <ol class="breadcrumb">
    <li class="breadcrumb-item">  <Button variant="link" as={Link} to="/dashboard"  > {t('navigation.dashboard')}</Button></li>
    <li class="breadcrumb-item active" aria-current="page" style={{ paddingTop:'5px'}}>{t('navigation.my_history')}</li>
  </ol>
            </nav> */}
            <div className="row">
                <div className="col-sm-3">
                    <h4 class="text-light bold"> {t('diagnosis_results.results')}</h4>
                </div>
            </div>
            <br />
            <div className="row">
                
                <div className="col-sm-3">
                    <button class="btn" style={{ background:"#28696D",borderColor:"#28696D", color:"#ffffff"}} onClick={()=>fetchAllDiagnosis()} >
                    <i class="fas fa-sync fa-fw"></i> {t('diagnosis_results.reload_results')}
                  </button>
                </div>
            </div>
                <div className="col-sm-12">
                  <Spinner animation={spin} variant="primary" />
                  {error ? <>
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  </> : <></>}
                  
                </div>
                </div>
        

            <div className="row" style={{paddingLeft:"50px"}}>
            {renderdiagnosis}

        </div>
        

    </div>  );
}
 
export default ValidateDiagnosis;