// import logo from './logo.svg';
import './assets/global.css';
import { useEffect, useReducer } from 'react';
import Nav from './components/Nav'
import Nav2 from './components/Nav2'
import Nav3 from './components/Nav3';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';


export const LanguageContext = React.createContext();
export const AuthContext = React.createContext();

const initiallanguagestate = 'de'
const initialauthstate = false;

const reducer = (state, action) => {
  switch (action) {

    case 'en':
      return 'en';
    case 'zh_TW':
      return 'zh_TW'
    default:
      return state
  }
};
const authreducer = (state, action) => {
  switch (action) {

    case 'true':
      return true;
    case 'false':
      return false
    default:
      return state
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initiallanguagestate)
  const [authstate, authDispatch] = useReducer(authreducer, initialauthstate)
  const { t, i18n } = useTranslation()
  useEffect(() => {
    // 
    i18n.changeLanguage(state)

  }, [state])
  return (
    <LanguageContext.Provider value={{ languagestate: state, languageDispatch: dispatch }}>

      <AuthContext.Provider value={{ authstate: authstate, authDispatch: authDispatch }}>
        <Nav2 />
      </AuthContext.Provider>

    </LanguageContext.Provider>
  );
}

export default App;
