import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import ShowNav from "./ShowNav";
// import { useAuth } from "./context/auth";
var  authTokens=''

function PrivateRoute({ component: Component, ...rest }) {
  // const authTokens = false;
  const [token, setToken] =useState('')
  var authTokens=sessionStorage.getItem('mytokenw',false);
  console.log(authTokens);

  useEffect(() => {
    var authTokens = sessionStorage.getItem('mytokenw', false);
    setToken(authTokens)
    
    
  },[])

  
  
  

  return (
    <>
      <ShowNav />
      {authTokens}
    <Route
      {...rest}
      render={props =>
        !authTokens ? (
          // <Component {...props} />
          <Redirect to="/signin" />
        ) : (
          <Redirect to="/signin" />
        )
      }
      />
       </>
      );
     
}

export default PrivateRoute;