import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { Spinner } from 'react-bootstrap';

import { Card, Button } from 'react-bootstrap';
import axios from 'axios';
import url from '../url';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
const Verify = () => {
    const { t, i18n } = useTranslation()
    let { token } = useParams();
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [spin, setSpin] = useState('false')
    const [success, setSuccess] = useState('')
    
    useEffect(() => {
        setSpin('grow')
        axios.put(url + 'auth/verify-account',{token})
            .then(res => {
            setSuccess(t('responses.verification_success'))
            }).catch(e => {
            setError(e.message)
            }).then(r => {
            setSpin('false')
        })
        
    },[])
    return (<div className="container" style={{ height: '100vh' }}>
        <br />
            <br />
        <div className="row">
            
            <div className="col-sm-3"></div>
            <div className="col-sm-6">

                {/* <Card style={{backgroundImage: `url("https://app-test.docsun.health/img/bg.9cd18d37.jpg")`, color:'white'}}> */}
                    <Card.Header ><div className="row">
                        <div className="col-sm-7">
                            <Image size='small' src='../../assets/docsun.png' /> 
                            
                        </div>
                        <div className="col-sm-4">
                            <span className="float-right" style={{color:'white'}}>Account Verification</span>

                        </div>
                    </div></Card.Header>
                    <Card.Body>
                        <div className="row">
                        <div className="col-sm-12">
                        <Spinner animation={spin} variant="primary" />
                        {error ? <>
                            <div className="alert alert-danger">
                                    {error}
                                    
                            </div>
                        </> : <></>}
                            {success ? <>
                                
                            <div className="alert alert-info">
                                    {success}
                                    
                                    
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Button
                            // className="btn btn-sm " 
                            style={{ background:"#28696D", color:"white", borderColor:"#28696D",height:"33px"}}
                             as={Link} to="/">{t('verification.go_login')}</Button>
                                    </div>
                                </div>
                                
                        </> : <></>}
                        
                        </div>
                    </div>

                    
                        
                </Card.Body>
                {/* </Card> */}

            </div>
            <div className="col-sm-3"></div>
        </div>
    </div>    );
}
 
export default Verify;