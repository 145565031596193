import React, { useEffect,Suspense, useContext } from 'react';
import { Trans,useTranslation } from 'react-i18next';
import { LanguageContext } from '../../App';
// import i18n from '../../i18n';
// import i18n from '../../i18n';
const Translate = () => {
    const { t, i18n } = useTranslation()
    // const languagecontext = useContext(LanguageContext)
    // // i18n.changeLanguage(languagecontext.languagestate)
    // useEffect(() => {
    //     // i18n
    //     i18n.changeLanguage('du')
    //     // console.log(languagecontext.languagestate)
        
    // },[languagecontext.languagestate])
    return (
        <>
             {/* <div className="form-group">
                    <label>
                      Language {languagecontext.languagestate}
                    </label>
                    <select className="form-control" value={languagecontext.languagestate} onChange={(e)=>i18n.changeLanguage(e.target.value)}>
                      <option value="en">English</option>
                      <option value="de">Taiwan</option>

                    </select>
            </div> */}
            <p>{t('title')}</p>
        
        <Trans i18nKey="description.part1">
            To get started, edit <code>src/App.js</code> and save to reload hhhh.

            </Trans>
            </>
    );
}
 
export default Translate;