import React, { useState } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Visibility,
} from 'semantic-ui-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '4em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}


const fixedMenuStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}

const overlayStyle = {
  float: 'left',
  margin: '0em 3em 1em 0em',
}

const fixedOverlayStyle = {
  ...overlayStyle,
  position: 'fixed',
  top: '80px',
  zIndex: 10,
}

const overlayMenuStyle = {
  position: 'relative',
  left: 0,
  transition: 'left 0.5s ease',
}

const fixedOverlayMenuStyle = {
  ...overlayMenuStyle,
  left: '800px',
}
const NavUi = () => {
    const [menuFixed, setMenuFixed] = useState(false)
    // const [menuFixed, setMenuFixed] = useState(false)
    return (
         <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
        >
          <Menu
            borderless
            fixed={menuFixed ? 'top' : undefined}
                          style={menuFixed ? fixedMenuStyle : menuStyle}
                          // style={{backgroundColor:'#ede6ed', backgroundImage: `url("https://app-test.docsun.health/img/bg.9cd18d37.jpg")`}}
          >
            <Container text >
              <Menu.Item>
                <Image size='small' src='https://app-test.docsun.health/img/docsun.70f9760e.png' />
              </Menu.Item>
              {/* <Menu.Item header>Docksun</Menu.Item> */}
              <Menu.Item as={Link} to="/dashboard">Dashboard</Menu.Item>
              <Menu.Item as={Link}>Tw</Menu.Item>
              <Menu.Item as={Link} to="/dashboard/faq">FAQ</Menu.Item>

              <Menu.Menu position='right'>
                <Dropdown text='Profile' pointing className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/changepassword">Change Password</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to="/signout">Sign Out</Dropdown.Item>
                    
                   
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Container>
          </Menu>
        </Visibility>
     );
}
 
export default NavUi;