import axios from 'axios';
import React, { useState } from 'react';
import { Spinner, Card, Button } from 'react-bootstrap';
import url from '../url';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const ChangePassword = () => {
     const { t, i18n } = useTranslation()
    const [error, setError] = useState('')
    const [errors, setErrors] = useState([])
    const [spin, setSpin] = useState('false')
    const [password, setPassword]=useState('')
    const [newpassword, setNewPassword]=useState('')
    const [confirmpassword, setConfirmPassword] = useState('')
    const [success, setSuccess]=useState('')
    
    const submitchange = () => {
        setError('')
        setSuccess('')
        if (password == '' || newpassword == '' || confirmpassword == '') {
            setError('All fields are mandatory')
            return;
        }
        if (newpassword != confirmpassword) {
            setError('New password and confirm password Mismatch')
            return;
        }
        setSpin('grow')
            var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      currentPassword: password,
      newPassword: newpassword
        };
        
        axios.post(url + 'auth/change-password',postData,config )
            .then(res => {
                console.log(res)
                setSuccess('Password Changed successfully')
            
            }).catch(e => {
                setError('Wrong Current password')
                console.log(e)
            
            }).then(r => {
                setSpin('false')
            
        })
    }
   

    return (<div className="container" style={{ height: '100vh', paddingTop:'30px' }}>
        
                    <nav aria-label="breadcrumb" style={{paddingTop:'30px'}}>
                
  <ol class="breadcrumb">
    <li class="breadcrumb-item">  <Button variant="link" as={Link} to="/dashboard"  > {t('navigation.dashboard')}</Button></li>
    <li class="breadcrumb-item active" aria-current="page" style={{ paddingTop:'5px'}}>{t('change_password.title')}</li>
  </ol>
            </nav>
        
        <div className="row alert" >
            <div className="row">
                <div className="col-sm-12">
                    <h3 style={{color:'white'}}><b>{t('change_password.title')}</b></h3>

                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <Spinner animation={spin} variant="primary" />
                
               
                  {error ? <>
                    <div className="alert alert-danger">
                      {error}
                    </div>
                </> : <></>}
                  {success ? <>
                    <div className="alert alert-info">
                      {success}
                    </div>
                </> : <></>}
            </div>
        </div>
       
                        <div className="row">
                    <div className="col-sm-12">
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                {t('change_password.form.current_password')}
                                            </label>
                                            <input type="password" className="form-control" value={password}
                                                onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6"></div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                 {t('change_password.form.new_password')}
                                            </label>
                                            <input type="password" className="form-control" value={newpassword}
                                                onChange={(e) => setNewPassword(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                {t('change_password.form.confirm_password')}
                                            </label>
                                            <input type="password" className="form-control" value={confirmpassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                    
                        </div>
                        <div className="row">

                            <div className="col-sm-12">
                                <input type="submit" value={t('change_password.form.button')} className="btn btn-primary"  onClick={submitchange} />
                            </div>
                        </div>
                                
                            </Card.Body>
                        </Card>
                    </div>
                </div>
        
    </div> );
}
 
export default ChangePassword;