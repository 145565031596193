import React, { Component,Suspense } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap'

 class FAQ extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { t } = this.props;


      return (
        
        <div className="container" style={{ height: "100vh" }}>
          <br />
          <br />
          <br />
                      <nav aria-label="breadcrumb">
                
  <ol class="breadcrumb">
    <li class="breadcrumb-item">  <Button variant="link" as={Link} to="/dashboard"  > {t('navigation.dashboard')}</Button></li>
    <li class="breadcrumb-item active" aria-current="page" style={{ paddingTop:'5px'}}>{t('navigation.faq')}</li>
  </ol>
            </nav>
          
          
              <div className="row">

              </div>
              <hr />
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
              <Icon name='dropdown' />
              
         {t('faq.faq1.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <p>
                          {t('faq.faq1.description')}
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq2.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <p>
                          {t('faq.faq2.description')}
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq3.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <p>
                          {t('faq.faq3.description')}
          </p>
          
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq4.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
          <p>
                           {t('faq.faq4.description')}
          </p>
          
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq5.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4}>
          <p>
                         {t('faq.faq5.description')}
          </p>
          
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 5}
          index={5}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq6.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 5}>
          <p>
                          {t('faq.faq6.description')}
          </p>
          
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 6}
          index={6}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq7.title')}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 6}>
          <p>
                          {t('faq.faq7.description')}
          </p>
          
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 7}
          index={7}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          {t('faq.faq8.title')} 
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 7}>
          <p>
                          {t('faq.faq8.description')}
          </p>
          
        </Accordion.Content>
              </Accordion>
              </div>
    )
  }
}

const MyComponent = withTranslation()(FAQ)

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  );
}