import React, { useContext } from 'react';
import { LanguageContext } from '../../App';
const NavContext = () => {
    const languagecontext = useContext(LanguageContext)
    
    return (
        <>
            <select onChange={(e)=>languagecontext.languageDispatch(e.target.value)} value={languagecontext.languagestate}>
                  <option value="en">En</option>
                  <option value="zh_TW">TW</option>
                </select>
                
            </>
     );
}
 
export default NavContext;