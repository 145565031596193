import axios from 'axios'
import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import url from '../url'
import Spinner from 'react-bootstrap/Spinner'
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
const SignUp = () => {
  const { t, i18n } = useTranslation()
  const [error, setError] = useState('')
  const [errors, setErrors] = useState([])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [spin, setSpin] = useState('')
  const [success, setSuccess] = useState(false)

  const [checked, setChecked] = useState(false)
  const handleClick = () => setChecked(!checked)

  var postData = {
    email: email,
    password: password,
    name: username,
    user_key: ''
  };

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      language: 'en',
      "Access-Control-Allow-Origin": "*",
    }
  };
  const createAccount = () => {
    setError('')


    if (!checked) {
      setError(t('responses.accept_terms'))

      return
    }
    if (email == '' || password == '' || username == '') {
      setError(t('responses.fields_required'))
      return;
    }
    if (password != confirmpassword) {
      setError('Password and confirm password mismatch');
      return;

    }
    setSpin('border')
    axios.post(url + 'auth/register', postData, axiosConfig
    )
      .then(res => {
        console.log(res.data)

      }).catch(e => {
        setError(e.message)
        console.log(e)

      }).then(r => {
        setSuccess(true)

      }
      )
  }
  return (
    <div className="container" style={{ height: "100vh" }}>
      {success ? (<>
        <Redirect to="/registration-success" />

      </>) : (<></>)}
      <div className="row">
        <div className="col-sm-2">

        </div>
        <div className="col-sm-8" >


          <Card style={{  borderColor: 'white', border: 'rounded-pill', borderRadius: "10px" }} >
            <div className="row" style={{ paddingLeft: '20px', paddingRight: '30px' }}>
              <div className="col-sm-3" />
              <div className="col-sm-5">
                <img src='../assets/Webappimages16/Logo.png' class="responsive" />
              </div>
              <div className="col-sm-3" />
            </div>
            <Card.Body>
              <div class="mb-3 text-center">
                <h4 class=" text-center">
                  <b>{t('login.title')}</b>
                </h4>
                <span class="text-center">
                  <h3> {t('login.subtitle')}</h3>
                </span>
              </div>
        


              <div className="row">
                <div className="col-sm-12">
                  <Spinner animation={spin} variant="primary" />
                  {error ? <>
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  </> : <></>}

                </div>
              </div>

              {/* <div className="row"> */}
              <div style={{ paddingLeft: '70px' }}>
                <div className="col-sm-9">
                  <div className="form-group">
                    <label className=" bold">
                      {t('signup.form.username_label')} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input type="text" className="form-control" placeholder={t('signup.form.username_placeholder')}
                      value={username} onChange={(e) => setUsername(e.target.value)} />
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="form-group">
                    <label className="bold">
                      {t('signup.form.email_label')} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input type="text" className="form-control" placeholder={t('signup.form.email_placeholder')}
                      value={email} onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {/* </div> */}

                </div>
                {/* <div className="row"> */}
                <div className="col-sm-9">
                  <div className="form-group">
                    <label className="bold">
                      {t('signup.form.password_label')} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input type="password" className="form-control" placeholder={t('signup.form.password_placeholder')}
                      value={password} onChange={(e) => setPassword(e.target.value)}

                    />
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="form-group">
                    <label className="bold">
                      {t('signup.form.cpassword_label')} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input type="password" className="form-control" placeholder={t('signup.form.cpassword_placeholder')}
                      value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                {/* </div> */}

                <div className="row" style={{ paddingLeft: '15px' }}>
                  <div className="col-sm-12">
                    <input onClick={handleClick} checked={checked} type="checkbox" /> <span>{t('signup.form.terms.agree')}<a href="https://docsun.health/privacy-policy/#terms" target="_blank" style={{ color: "#9c9c9c" }} > {t('signup.form.terms.link')}</a> </span>


                  </div>

                </div>

                <div className="row" style={{ paddingLeft: '15px' }}>

                  <div className="col-sm-4">
                    <input type="button" className="btn btn-primary" value={t('signup.form.button')} onClick={createAccount} />
                  </div>
                </div>

              </div>



            </Card.Body>
          </Card>
          <br />
          <div className="row">
            <div className="col-sm-12" style={{ paddingLeft: '110px' }}>
              {/* {t('signup.form.check_account')} */}
              <Button variant="link" as={Link} to="signin" style={{ background: "#1d7ced", borderColor: "#269E9E", color: "#ffffff" }}>{t('signup.form.login')}</Button>
            </div>
            {/* <button variant="primary" className="btn btn-sm" style={{ background: "#1d7ced", borderColor: "#269E9E", color: "#ffffff" }} onClick={login}> {t('login.form.signin')}</button> */}
          </div>




        </div>
        <div className="col-sm-2">

        </div>
      </div>

    </div >

  )
}


export default SignUp