import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { Redirect } from "react-router-dom";
import HideNav from './HideNav';
const SignOut = () => {
    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()


    }, [])
    return (<div className="container">
        <HideNav />
        <Redirect to="/signin" />

        <Spinner animation='border' variant="primary" />


    </div>);
}

export default SignOut;