import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../App';
const HideNav = () => {
    const authcontext = useContext(AuthContext)
    useEffect(() => {
        authcontext.authDispatch('false')
        
    },[])
    return ( <></> );
}
 
export default HideNav;