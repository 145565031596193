import React, { useContext, useState } from 'react';

import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Visibility,
} from 'semantic-ui-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import NavContext from './NavContext';
import { Trans, useTranslation } from 'react-i18next';
import { AuthContext } from '../../App';
const fixedMenuStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}
const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '4em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}
const Navbar = () => {
  const [menuFixed, setMenuFixed] = useState(true)

  const { t, i18n } = useTranslation()
  const authcontext = useContext(AuthContext)

  return (
    <div style={{ paddingBottom: '1px' }} className="text16">
      {authcontext.authstate ? <>
        <Menu
          borderless
          fixed={menuFixed ? 'top' : undefined}
          style={menuFixed ? fixedMenuStyle : menuStyle}
          // style={{ backgroundColor: '#1c448d', color: 'red' }}
         

          className="text16"
        >

          {/* <Container text > */}
          <div className="container" >
            <Menu.Item as={Link} to="/dashboard">
              
                <Image size='small' src='../../assets/Webappimages16/Logo.png' height='500px' />
              {/* <img src="../../assets/Webappimages16/Logo.png" height /> */}
            </Menu.Item>
            {/* <Menu.Item header>Docksun</Menu.Item> */}
            {/* <Menu.Item as={Link} to="/dashboard">{t('navigation.dashboard')}</Menu.Item> */}



            <Menu.Menu position='right'>
              <Menu.Item as={Link}><NavContext /></Menu.Item>
              <Menu.Item as={Link} to="/dashboard/faq" style={{ paddingLeft: "-50px", }} >{t('navigation.faq')}</Menu.Item>
              <Dropdown text={t('navigation.profile')} pointing className='link item'>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/profile" >{t('navigation.profile')}</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/changepassword">{t('navigation.change_password')}</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/signout">{t('navigation.logout')}</Dropdown.Item>


                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>


            {/* </Container> */}
          </div>
        </Menu>
      </> : <>
        <br />
        <center><Menu.Item as={Link}><NavContext /></Menu.Item></center>
      </>}
      <br />
      <br />
    </div>

  );
}

export default Navbar;