import React from 'react';
const VideoPlayer = (props) => {

    // console.log('props')
    console.log(props)
    // alert('yes')
    return (<>
       
        {props.video==undefined? <></>:<> <video width="150" controls>
    <source src={URL.createObjectURL(props.video)}/>
</video> </>}
        {/* <video width="150" controls>
    <source src={URL.createObjectURL(props.video)}/>
</video> */}
    </>);
}
 
export default VideoPlayer;