import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from './url';
import { Spinner, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Profile = () => {
    const { t, i18n } = useTranslation()
    
  const [error, setError] = useState('')
  const [token, setToken] = useState('')
    const [errors, setErrors] = useState([])
    const [spin, setSpin] = useState('false')
    const [user, setUser]=useState({})
    
    useEffect(() => {
        var mytoken = sessionStorage.getItem('mytoken', false);
    setToken(mytoken)
        fetchprofile()
        
        
    }, [])
    const fetchprofile = () => {
        setSpin('grow')
        var token=sessionStorage.getItem('mytoken', false)
        const config = {
    headers: { Authorization: `Bearer ${token}` }
};
        axios.get(url + 'auth/profile',config )
            .then(res => {
                // console.log(res.data.result)
                setUser(res.data.result)
            
            }).catch(e => {
                setError(e.message)
            
            }).then(r => {
                setSpin('false')
            
        })
    }
    return (<div className="container" style={{ height: '100vh', font: '15px' }}>
        <br />

                            <nav aria-label="breadcrumb" style={{paddingTop:'30px'}}>
                
  <ol class="breadcrumb">
    <li class="breadcrumb-item">  <Button variant="link" as={Link} to="/dashboard"  > {t('navigation.dashboard')}</Button></li>
    <li class="breadcrumb-item active" aria-current="page" style={{ paddingTop:'5px'}}>{t('profilepage.title')}</li>
  </ol>
            </nav>
        
        <div className="row">
                <div className="col-sm-12">
                <Spinner animation={spin} variant="primary" />
               
                  {error ? <>
                    <div className="alert alert-danger">
                      {error}
                    </div>
                </> : <></>}
                <div className="row">
            <div className="col-sm-12 alert">
                <h3 style={{color:'white'}} ><b>{t('profilepage.title')}</b></h3>

            </div>
        </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Card>
                            <Card.Body>
                                <p>
                                    {t('profilepage.name')}: {user.name}
                                </p>
                                <p>
                                    {t('profilepage.email')}: {user.email}
                                </p>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                  
                </div>
              </div>


    </div>);
}
 
export default Profile;