import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
// import { useState } from 'react/cjs/react.development';
import { Redirect } from "react-router-dom";
const ValidateDiagnosis = () => {
    const [diagnosiskey, setDiagnosisKey] = useState('')
    const [redirect, setRedirect] = useState(false)

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    localStorage.setItem('diagnosis_key', id);
    useEffect(() => {

        //                   const search = useLocation().search;
        // const id = new URLSearchParams(search).get('id');
        localStorage.setItem('diagnosis_key', id);
        sessionStorage.setItem('diagnosis_key', id);

        // GdGj4Md5ujjLUQ9WvOLR


    }, [])

    const resolveAfter2Seconds = () => {

        localStorage.setItem('diagnosis_key', id);

        setTimeout(() => {
            // resolve('resolved');
            // console.log(sessionStorage.getItem('diagnosis_key'))
            setRedirect(true)
        }, 2000);

        //         return new Promise(resolve => {

        //     setTimeout(() => {
        //         resolve('resolved');
        //         // console.log(sessionStorage.getItem('diagnosis_key'))
        //         setRedirect(true)
        //     }, 2000);
        //   });
    }

    async function asyncCall() {
        //   console.log('calling');
        const result = await resolveAfter2Seconds();
        //   console.log(result);
        // expected output: "resolved"
    }

    asyncCall();




    return (<>
        <div className="row">
            <div className="col-sm-12 alert alert-info">
                Data validated succesfully redirecting to login

            </div>
        </div>
        {redirect ? <> <Redirect to="/" /></> : <></>}

    </>);
}

export default ValidateDiagnosis;