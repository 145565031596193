import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../App';
const ShowNav = () => {
    const authcontext = useContext(AuthContext)
    useEffect(() => {
        authcontext.authDispatch('true')
        
    },[])
    return ( <></> );
}
 
export default ShowNav;